





















import { Component, Mixins } from 'vue-property-decorator';
import { ProfileFilter } from 'client-website-ts-library/filters';
import { Office, Profile, WebsiteProfileDisplayType } from 'client-website-ts-library/types';
import { API, Config } from 'client-website-ts-library/services';
import { View } from 'client-website-ts-library/plugins';

import ProfileCards from '../components/ProfileCards.vue';
import Loader from '../components/UI/Loader.vue';

@Component({
  components: {
    ProfileCards,
    Loader,
  },
})
export default class MeetTheTeam extends Mixins(View) {
  private profiles: Profile[] = [];

  private office: Office | null = null;

  mounted() {
    const filter = new ProfileFilter();

    API.Profiles.Search(new ProfileFilter()).then((profiles) => {
      this.profiles = profiles.filter((p) => p.DisplayType !== WebsiteProfileDisplayType.Principal);
    });

    API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
      this.office = office;
    });
  }

  get directors(): Profile[] {
    return this.profiles.filter((p) => p.DisplayType === WebsiteProfileDisplayType.Principal);
  }

  get agents(): Profile[] {
    return this.profiles.filter((p) => p.DisplayType === WebsiteProfileDisplayType.Agent);
  }

  get propertyManagers(): Profile[] {
    return this.profiles.filter((p) => p.DisplayType === WebsiteProfileDisplayType.PropertyManager);
  }

  get support(): Profile[] {
    return this.profiles.filter((p) => p.DisplayType === WebsiteProfileDisplayType.PersonalAssistant);
  }
}
